import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getDigitalizationAndAutomationPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/reseni/digitalizace-a-automatizace",
		[Locale.en]: "/solutions/digitalization-and-automation",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
